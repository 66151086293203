<template>
    <v-container fluid>
        <v-data-table :items="users" :loading="loading" :headers="tableHeaders">
            <template v-slot:top>
                <v-tooolbar flat>
                    <v-btn color="success" style="margin: 5px;">Új felhasználó</v-btn>
                </v-tooolbar>
            </template>
            <template v-slot:item.name="{ item }">
                {{ item.name }} ({{ item.username }})
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn icon color="red" x-small @click="deleteUser(item)">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn v-if="!item.active" icon color="green" x-small @click="activateUser(item)">
                    <v-icon>mdi-check</v-icon>
                </v-btn>
            </template>

            <template v-slot:item.active="{item}">
                <v-icon v-if="item.active" color="green">mdi-check</v-icon>
                <v-icon v-else color="red">mdi-check</v-icon>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import { AuthAPI } from "../../api/auth";
export default {
    name: "users",
    data: () => ({
        loading: true,
        users: [],
        tableHeaders: [
            {
                text: "#",
                sortable: false,
                value: "id",
            },
            {
                text: "Név",
                sortable: false,
                value: "name",
            },
            {
                text: "Aktív?",
                sortable: false,
                value: "active"
            },
            {
                text: "Műveletek",
                sortable: false,
                value: "actions"
            }

        ]
    }),
    mounted: function () {
        this.$store.commit("app/setTitle", "Felhasználók");
        AuthAPI.getUsers().then((response) => {
            this.users = response.data;
        }).finally(() => this.loading = false);
    },
    methods: {
        deleteUser: function (user) {
            const question = user.active ? `Biztos deaktiválni akarod a ${user.username} felhasználót?` : `Biztos szeretnéd véglegesen törölni ${user.usernmae} felhasználót? MINDEN ADATA TÖRLŐDNI FOG!`;
            if (user.id == this.$store.state.auth.userData.id) {
                alert("Saját felhasználód nem törölheted!");
            }
            else if (confirm(question)) {
                AuthAPI.deleteUser(user.id).then(() => {
                    window.location.reload();
                });
            }
        },
        activateUser: function (user) {
            if (confirm(`Vissza szeretnéd aktiválni ${user.username} felhasználót?`)) {
                AuthAPI.activateUser(user.id).then(() => {
                    window.location.reload();
                });
            }
        }
    }
};
</script>